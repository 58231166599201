import React from "react";
import {useIntl} from "react-intl";

export function DataItem({children, className = "", labelId, i18nPrefix}) {
  const intl = useIntl();

  const messageId = (i18nPrefix)
    ? `${i18nPrefix}.${labelId}.label`
    : `${labelId}.label`;

  return (
    <div className={`data-item ${className}`}>
      <span className="data-label ">
        {intl.formatMessage({id: messageId})}
      </span>
      <span className="data-value">
        {children}
      </span>
    </div>
  );
}
