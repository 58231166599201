import React from "react";

import {ArchiveButton} from "@/library/controls/archive_button.jsx";

export function CompilerActionsTile({compiler, confirming, setConfirming}) {
  return (
    <div className="compiler-card-actions-tile">
      <ArchiveButton className="action-button compiler-action-button"
        entity={compiler}
        confirming={confirming}
        i18nPrefix="operations.compilers.compiler"
        session={compiler.session}
        setConfirming={setConfirming}
      />
    </div>
  );
}
