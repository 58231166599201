import Aqumen from "@aqumen/sdk";

const PING_INTERVAL_MS = 20_000;
const PING_TIMEOUT_MS = PING_INTERVAL_MS * 3;

import {update} from "@/data/update.js";
import {fetchControlServer} from "@/data/control_server/fetch_control_server.js";
import {fetchJob} from "@/data/job/fetch_job.js";
import {fetchUser} from "@/data/user/fetch_user.js";

export async function websocket(dispatch, session) {
  const socket = await Aqumen.Service.websocket(session);
  // console.debug(`Opened websocket to ${session.url}`);

  let lastPong = null;
  let pingInterval = null;

  socket.addEventListener("message", async (event) => {
    const data = JSON.parse(event.data);
    if (data === "pong") {
      lastPong = Date.now();
    } else if (data.data) {
      // console.debug(`Websocket data from ${session.url}`, data);

      switch (data.type) {
        case "controlServer":
          dispatch(update(session, await fetchControlServer(session, data.data.controlServer.id)));
          break;
        case "job":
            dispatch(update(session, await fetchJob(session, data.data.job.id)));
            break;
        case "user":
          dispatch(update(session, await fetchUser(session, data.data.user.id)));
          break;
        default:
          dispatch(update(session, data.data[data.type]));
      }
    } else {
      console.warn(`Websocket message from ${session.url} event not understood`, event);
    }
  });
  socket.addEventListener("close", (event) => {
    console.warn(`Websocket with ${session.url} closed`, event);
    clearInterval(pingInterval);
  });
  pingInterval = setInterval(async () => {
    if  (lastPong && Date.now() - lastPong > PING_TIMEOUT_MS) {
      try {
        console.warn(`Closing and reconning connecting websocket to ${session.url}, ping delta is greater than timeout`, {lastPong, PING_TIMEOUT_MS});
        await socket.close();
        await socket.reconnect();
        lastPong = null;
      } catch(e) {
        console.warn(`Unable to reconnect socket to ${session.url}`, e);
      }

    } else {
      socket.send(JSON.stringify("ping"));
    }
  }, PING_INTERVAL_MS);
  return socket;
}
