import React, {useState} from "react";
import {useIntl} from "react-intl";

import {DisplayId} from "@/library/display/display_id.jsx";
import {editableRole} from "./editable_role.js";
import {RoleArchiveButton} from "./role_archive_button.jsx";
import {RoleDescriptionTile} from "./role_description_tile.jsx";
import {RoleIdentifierTile} from "./role_identifier_tile.jsx";

const MINIMUM_SAFE_PERMISSIONS = [
  {action: "read", target: "roles", targetOrganization: "any"},
  {action: "read", target: "permissions", targetOrganization: "any"},
  {action: "read", target: "users", targetOrganization: "any"},
  {action: "update", target: "rolePermissions"},
  {action: "update", target: "userRoles"}
];

export function RoleCard(props) {
  const intl = useIntl();
  const [confirmingArchive, setConfirmingArchive] = useState(false);

  const allowArchive = editableRole(props.role) && props.roles.find(r => {
    return r.id !== props.role.id
      && MINIMUM_SAFE_PERMISSIONS.every(msp => _.find(r.permissions, msp));
  });

  let className = "role-card card focusable-item";
  let i18nPrefix = "roles.role";
  if (props.role.archivedAt) {
    className += " archived";
    i18nPrefix += ".archived";
  }
  if (confirmingArchive) {
    className += " confirming confirming-archive";
  }

  return (
    <div className={className}>
      <div className="role-card-inputs">
        <RoleIdentifierTile role={props.role}/>
        <RoleDescriptionTile role={props.role}/>
      </div>

      <div className="role-card-meta">
        <div className="role-card-ids">
          <div className="role-card-aqumen-id">
            <span className="data-label">
              {intl.formatMessage({id: `roles.role.id.aqumen.label`})}
            </span>
            <span className="data-value">
              <DisplayId id={props.role.id} disabled={confirmingArchive}/>
            </span>
          </div>
        </div>
        <div className="role-card-actions">
          <RoleArchiveButton disabled={!allowArchive}
            role={props.role}
            setConfirming={setConfirmingArchive}/>
        </div>
      </div>
    </div>
  );
}
