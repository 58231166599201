import Aqumen from "@aqumen/sdk";
import React, {useContext, useState} from "react";

import {ActionsTile} from "@/library/controls/actions_tile.jsx";
import {AqumenIdItem} from "@/library/controls/aqumen_id_item.jsx";
import {ArchiveButton} from "@/library/controls/archive_button.jsx";
import {Card} from "@/library/layout/card.jsx";
import {CardContext} from "@/library/layout/card_context.js";
import {CardData} from "@/library/layout/card_data.jsx";
import {CardMetadata} from "@/library/layout/card_metadata.jsx";
import {DataItem} from "@/library/layout/data_item.jsx";
import {DisplayDuration} from "@/library/display/display_duration.jsx";
import {TimestampDisplay} from "@/library/display/timestamp_display.jsx";

import {ApprovalButton} from "./approval_button.jsx";
import {CardTitle} from "@/library/layout/card_title.jsx";

export function AdministrationReservationCard({reservation, session}) {
  const [confirming, setConfirming] = useState(false);
  const [message, setMessage] = useState(reservation.message || "");

  const start = reservation.activateAt;
  const end = start + reservation.duration;
  const pendingApproval = !reservation.approvedAt && !reservation.rejectedAt;
  const disableApprovalActions = !message;

  const handleMessageChange = ev => setMessage(ev.target.value);

  let className = "focusable-item";
  let messageI18nPrefix = "message";
  if (reservation.approvedAt) {
    className += " approved";
    messageI18nPrefix += ".approved";
  } else if (reservation.rejectedAt) {
    className += " rejected";
    messageI18nPrefix += ".rejected";
  } else if (!reservation.archivedAt) {
    className += " pending";
  }

  return (
    <CardContext.Provider value={{
      entity: reservation,
      archived: Boolean(reservation.archivedAt),
      confirming: confirming,
      setConfirming: setConfirming
    }}>
      <Card className={className} entity={reservation}
        i18nPrefix="administration.reservations.reservation">
        {pendingApproval && !reservation.archivedat && (
          <CardTitle i18nPrefix="administration.reservations.reservation.pending"/>
        )}
        <CardData>
          <DataItem labelId="controlServer">
            {reservation.controlServer?.identifier || "-"}
          </DataItem>
          <DataItem labelId="start">
            <TimestampDisplay value={start} forceTime={true}/>
          </DataItem>
          <DataItem labelId="end">
            <TimestampDisplay value={end} forceTime={true}/>
          </DataItem>
          <DataItem labelId="duration">
            <DisplayDuration value={end - start}/>
          </DataItem>
          <DataItem labelId="recipient">
            {reservation.recipient?.fullName} &lt;{reservation.recipient?.email}l&gt;
          </DataItem>
          <DataItem labelId="description">
            {reservation.description}
          </DataItem>
          <DataItem labelId={messageI18nPrefix} className="approval">
            {pendingApproval && !reservation.archivedAt && (
              <textarea onChange={handleMessageChange} value={message}/>
            )}
            {!pendingApproval && message && (
              <>{message}</>
            )}
          </DataItem>
        </CardData>

        <CardMetadata>
          <AqumenIdItem entity={reservation}/>
        </CardMetadata>

        <ActionsTile className="card-actions" pane="administration.reservations.card">
          {pendingApproval && (
            <ApprovalButton
              action="reject"
              disabled={disableApprovalActions}
              message={message}
              reservation={reservation}
              session={session}/>
          )}
          {pendingApproval && (
            <ApprovalButton
              action="approve"
              disabled={disableApprovalActions}
              message={message}
              reservation={reservation}
              session={session}/>
          )}
          <ArchiveButton entity={reservation}
            className="action"
            disabled={reservation.archivedAt}
            session={session}
            confirming={confirming}
            setConfirming={setConfirming}/>
        </ActionsTile>
      </Card>
    </CardContext.Provider>
  );
}
