import React from "react";
import {useIntl} from "react-intl";

export function CardTitle({i18nPrefix}) {
  const intl = useIntl();

  return (
    <h2 className="card-title">
      {intl.formatMessage({id: `${i18nPrefix}.title`})}
    </h2>
  );
}
