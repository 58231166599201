import React, {useState} from "react";

import {ConfirmableButton} from "@/library/controls/confirmable_button.jsx";

export function SimpleConfirmingButton({className, disabled, messageId, onClick}) {
  const [confirming, setConfirming] = useState(false);

  const handleClick = (ev) => {
    if (ev.confirmed) {
      return onClick(ev);
    }
  }

  let currentClassName = className;
  if (confirming) {
    currentClassName += " confirming";
  }

  return (
    <ConfirmableButton className={currentClassName}
      confirming={confirming}
      disabled={disabled}
      setConfirming={setConfirming}
      messageId={messageId}
      onClick={handleClick}
    />
  );
}
