export function hydrateJobs(controlServers, queues, simulators, users, jobs) {
  return jobs.map((job) => {
    let queuePosition = -1;
    if (!job.exitedAt) {
      queues.every((queue) => {
        queuePosition = queue?.queued?.findIndex(q => q.id === job.id);
        return (queuePosition === -1);
      });
    }
    const controlServer = (job.controlServer?.id)
      ? controlServers.find(c => c.id === job.controlServer.id)
      : null;
    const simulator = (job.simulator?.id)
      ? simulators.find(s => s.id === job.simulator.id)
      : null;
    const createdBy = users.find(u => u.id === job.createdBy?.id);
    return Object.assign({}, job, {
      controlServer,
      createdBy,
      queuePosition,
      simulator
    });
  });
}
