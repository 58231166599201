import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";

import {RegisterPasskeyTile} from "@/authentication/register_passkey_tile.jsx";

export function AccountPasskeySyncPopover(props) {
  const intl = useIntl();

  return (
    <div className="account-passkey-sync-confirmation-backdrop" onClick={props.onClose}>
      <div className="account-passkey-sync-confirmation">
        <div className="account-passkey-sync-help">
          {intl.formatMessage(
            {id: "account.passkeys.tile.sync.help"},
            {name: (<span className="replacement-name">{props.passkey.name}</span>), p: c => (<p>{c}</p>)}
          )}
        </div>
        <RegisterPasskeyTile
          i18nPrefix="account.passkeys.tile.sync"
          replacePasskey={props.passkey}
          doneCallback={props.onClose}
        />
        <button className="account-passkey-sync-cancel" onClick={props.onClose}>
          {intl.formatMessage({id: "account.passkeys.tile.sync.cancel.button"})}
        </button>
      </div>
    </div>
  );
}
