import "./boilerplate/main.css";
import "./boilerplate/normalize.css";
import "./html.scss";

import "./color_palette.scss";
import "./color_foundation.scss";
import "./color_scheme.scss";

import "./common.scss";
import "./keyframes.scss";
import "./layout.scss";
import "./typography.scss";

import "./entity_list.css";
import "./errors.scss";
import "./forms.scss";
import "./navigation_animation.scss";
import "./navigation_menu.scss";
import "./settings_tile.scss";

import "./views/agreements.scss";
import "./views/authentication.scss";
import "./views/tabbed.scss";
import "./views/upgrade.scss";

import "./panes/about.scss";
import "./panes/coming_soon.scss";
import "./panes/dashboard.scss";
import "./panes/jobs.scss";
import "./panes/systems.scss";

import "./panes/access_control/access_control.scss";
import "./panes/access_control/access_control_permissions.scss";
import "./panes/access_control/access_control_roles.scss";
import "./panes/access_control/access_control_users.scss";

import "./panes/account/account.scss";
import "./panes/account/account_passkeys.scss";
import "./panes/account/account_reservations.scss";
import "./panes/account/account_tokens.scss";

import "./panes/administration/administration_organizations.scss";
import "./panes/administration/administration_reservations.css";

import "./panes/operations/operations_compilers.scss";
import "./panes/operations/operations_configurations.scss";
import "./panes/operations/operations_control_servers.scss";
import "./panes/operations/operations_simulators.scss";
