import React from "react";

import {AccountPropertyAzureID} from "./account_property_azure_id.jsx";
import {AccountEmailTile} from "./account_email_tile.jsx"
import {AccountFullNameTile} from "./account_full_name_tile.jsx"


export function AccountPropertiesPanel(props) {
  return (
    <div className="account-properties-panel">
      <AccountEmailTile user={props.user} i18nPrefix="account.properties"/>
      <AccountFullNameTile user={props.user} i18nPrefix="account.properties"/>
      <AccountPropertyAzureID user={props.user}/>
    </div>
  );
}
