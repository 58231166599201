// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pane.account-pane {
  color: var(--color-elevated-text);
  min-width: 35rem;
  max-width: 60rem;
  text-align: left;
}
.pane.account-pane input[type=email], .pane.account-pane input[type=text] {
  min-width: 20rem;
  width: calc(100% - 12rem);
  box-sizing: border-box;
}
.pane.account-pane .account-property-help {
  color: var(--color-base-text);
  font-size: var(--font-size-input-help);
  margin-left: 11rem;
}
.pane.account-pane .account-property-azure-customer-subscription-id input {
  font-family: ui-monospace;
  font-size: 1rem;
}
.pane.account-pane .account-settings-panel {
  margin-left: 10rem;
  padding: 0.5rem 1rem 1rem 1rem;
}
.pane.account-pane .account-settings-panel-title {
  border-top: var(--border-elevated);
  font-size: 1.2rem;
  margin: 0.5rem 0;
  padding-top: 1rem;
}
.pane.account-pane .account-setting.radio {
  margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/style/panes/account/account.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AACF;AACE;EACE,gBAAA;EACA,yBAAA;EACA,sBAAA;AACJ;AAEE;EACE,6BAAA;EACA,sCAAA;EACA,kBAAA;AAAJ;AAGE;EACE,yBAAA;EACA,eAAA;AADJ;AAIE;EACE,kBAAA;EACA,8BAAA;AAFJ;AAKE;EACE,kCAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;AAHJ;AAME;EACE,gBAAA;AAJJ","sourcesContent":[".pane.account-pane {\n  color: var(--color-elevated-text);\n  min-width: 35rem;\n  max-width: 60rem;\n  text-align: left;\n\n  input[type=\"email\"], input[type=\"text\"] {\n    min-width: 20rem;\n    width: calc(100% - 12rem);\n    box-sizing: border-box;\n  }\n\n  .account-property-help {\n    color: var(--color-base-text);\n    font-size: var(--font-size-input-help);\n    margin-left: 11rem;\n  }\n\n  .account-property-azure-customer-subscription-id input {\n    font-family: ui-monospace;\n    font-size: 1rem;\n  }\n\n  .account-settings-panel {\n    margin-left: 10rem;\n    padding: 0.5rem 1rem 1rem 1rem;\n  }\n\n  .account-settings-panel-title {\n    border-top: var(--border-elevated);\n    font-size: 1.2rem;\n    margin: 0.5rem 0;\n    padding-top: 1rem;\n  }\n\n  .account-setting.radio {\n    margin-top: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
