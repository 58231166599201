import Aqumen from "@aqumen/sdk";
import React from "react";

import {SimpleConfirmingButton} from "@/library/controls/simple_confirming_button.jsx";

export function ApprovalButton({action, disabled, i18nPrefix, message, reservation, session}) {
  const handleApprovalClick = (action) => (ev) => {
    if (ev.confirmed) {
      return Aqumen.Reservation[action](session, {id: reservation.id}, message);
    }
  };

  return (
    <SimpleConfirmingButton className={`${action} action`}
      disabled={disabled}
      messageId={`${i18nPrefix}.${action}.button.label`}
      onClick={handleApprovalClick(action, message)}
    />
  );
}
