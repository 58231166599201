import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {ActionsTile} from "@/library/controls/actions_tile.jsx";
import {loadAndUnloadArchivable} from "@/data/load_and_unload_archivable.js";
import {selectAppSetting} from "@/data/preferences/select_app_setting.js";
import {selectHydratedReservations} from "@/data/reservation/select_hyrdrated_reservations.js";
import {selectIncludeArchived} from "@/data/preferences/select_include_archived.js";
import {selectSessions} from "@/data/select_sessions.js";
import {SettingsTile} from "@/library/controls/settings_tile.jsx";
import {SettingToggleButton} from "@/library/controls/setting_toggle_button.jsx";
import {SettingToggleCheckbox} from "@/library/controls/setting_toggle_checkbox.jsx";

import {AdministrationReservationCard} from "./administration_reservation_card.jsx";
import {AdministrationReservationCardNew} from "./administration_reservation_card_new.jsx";

export function AdministrationReservationsPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const includeArchived = useSelector(selectIncludeArchived("administration.reservations"));
  const reservations = useSelector(selectHydratedReservations("administration.reservations"));
  const sessions = useSelector(selectSessions);

  const showNewCardAlways = useSelector(selectAppSetting("administration.reservations.showNewCardAlways"));
  const showNewCardOnce = useSelector(selectAppSetting("administration.reservations.showNewCardOnce"));

  const showNewCard = showNewCardAlways || showNewCardOnce;

  useEffect(
    loadAndUnloadArchivable("Reservation", dispatch, sessions, includeArchived),
    [includeArchived]
  );

  return (
    <div className="pane focusable-collection administration-reservations">
      <div className="pane-top">
        <h1 className="pane-title">
          {intl.formatMessage({id: "administration.reservations.title"})}
        </h1>
        <ActionsTile pane="administration.reservations" className="pane-actions">
          {!showNewCardAlways && (
            <SettingToggleButton className="show-new-card-once" setting="showNewCardOnce"/>
          )}
        </ActionsTile>
        <SettingsTile pane="administration.reservations">
          <SettingToggleCheckbox setting="includeArchived"/>
          <SettingToggleCheckbox setting="showNewCardAlways"/>
        </SettingsTile>
      </div>

      <div className="entity-list">
        {showNewCard && <AdministrationReservationCardNew/>}

        {reservations.map(r => (
          <AdministrationReservationCard
            key={r.id}
            reservation={r}
            session={r.session}
          />
        ))}
      </div>
    </div>
  );
}
