import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {AccountReservationCard} from "./account_reservation_card.jsx";
import {AccountReservationCardNew} from "./account_reservation_card_new.jsx";
import {loadAndUnloadArchivable} from "@/data/load_and_unload_archivable.js";
import {SettingsTile} from "@/library/controls/settings_tile.jsx";
import {SettingToggleCheckbox} from "@/library/controls/setting_toggle_checkbox.jsx";
import {selectAppSetting} from "@/data/preferences/select_app_setting.js";
import {selectWithSessions} from "@/data/select_with_sessions.js";

export function AccountReservationsPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const selectIncludeArchived = selectAppSetting("account.reservations.includeArchived");

  const selectCurrentUsersReservations = createSelector(
    selectWithSessions("reservation"),
    s => s.accessControlSession,
    (rs, acs) => rs.filter(r => r.recipient?.id === acs.user.id)
  );

  const selectFilteredReservations = createSelector(
    selectCurrentUsersReservations,
    selectIncludeArchived,
    (ur, ia) => (ia) ? ur : ur.filter(r => !r.archivedAt)
  );

  const selectOrderedReservations = createSelector(
    selectFilteredReservations, u => _.orderBy(u,
      ["activateAt", "duration", "createdAt"],
      ["desc", "desc", "desc"]
    )
  );

  const includeArchived = useSelector(selectIncludeArchived);
  const reservations = useSelector(selectOrderedReservations);
  const sessions = useSelector(s => s.environmentSession);

  useEffect(
    loadAndUnloadArchivable("Reservation", dispatch, sessions, includeArchived),
    [includeArchived]
  );


  // let unload = () => unloadArchived(
  //   "Reservation", dispatch, sessions, (r) => r.createdById === session.user.id
  // );

  return (
    <div className="account-reservations-pane pane focusable-collection">
      <div className="pane-top">
        <h1 className="account-reservations-title pane-title">
          {intl.formatMessage({id: "account.reservations.title"})}
        </h1>
        <SettingsTile pane="account.reservations">
          <SettingToggleCheckbox setting="includeArchived"/>
        </SettingsTile>
      </div>

      <div className="account-reservations-list">
        <AccountReservationCardNew/>

        {reservations.map(r => (
          <AccountReservationCard
            key={r.id}
            reservation={r}
            session={r.session}
          />
        ))}
      </div>
    </div>
  );
}
