import React from "react";

import {ArchiveButton} from "@/library/controls/archive_button.jsx";
import {ControlServerActionButton} from "./control_server_action_button.jsx";
import {ControlServerToggleLockButton} from "./control_server_toggle_lock_button.jsx";

export function ControlServerActionsTile({confirming, controlServer, setConfirming}) {
  return (
    <div className="control-server-card-actions-tile">

      <ControlServerActionButton
        action="terminateAll"
        confirming={confirming}
        controlServer={controlServer}
        setConfirming={setConfirming}
      />
      <ControlServerActionButton
        action="restart"
        confirming={confirming}
        controlServer={controlServer}
        setConfirming={setConfirming}
      />
      <ControlServerToggleLockButton
        confirming={confirming}
        controlServer={controlServer}
      />
      <ArchiveButton className="action-button control-server-action-button"
        entity={controlServer}
        confirming={confirming}
        i18nPrefix="operations.controlServers.controlServer"
        session={controlServer.session}
        setConfirming={setConfirming}
      />
    </div>
  );
}
