import Aqumen from "@aqumen/sdk";
import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useDebouncedCallback} from "use-debounce";

import {ArchiveButton} from "@/library/controls/archive_button.jsx";
import {DisplayId} from "@/library/display/display_id.jsx";
import {OrganizationIdentifierInput} from "./organization_identifier_input.jsx";
import {OrganizationPrunePeriodInput} from "./organization_prune_period_input.jsx";

export function OrganizationCard(props) {
  const intl = useIntl();
  const rendered = useRef(false);

  const [confirmingArchive, setConfirmingArchive] = useState(false);
  const [organization, setOrganization] = useState(props.organization);

  const session = useSelector(s => s.accessControlSession);

  const submit = useDebouncedCallback(() => {
    Aqumen.Organization.update(session,
      {id: organization.id}, {
        identifier: organization.identifier,
        prunePeriod: organization.prunePeriod
      }
    )
  }, 1000);

  const changeOrganization = property => event => {
    setOrganization(o => ({...o, [property]: event.target.value}));
  };

  useEffect(() => {
    if (!rendered.current) {
      rendered.current = true;
      return;
    }

    submit();
  }, [organization]);

  const editingDisabled = organization.archivedAt || confirmingArchive
  let className = "organization-card card focusable-item";
  let messageKey = "unarchived";

  if (props.organization.archivedAt) {
    className += " archived";
    messageKey = "archived";
  }

  if (confirmingArchive) {
    className += " confirming confirming-archive";
  }

  return (
    <div className={className}>
      <div className="organization-card-inputs">
        <OrganizationIdentifierInput organization={organization}
          disabled={editingDisabled}
          onChange={changeOrganization("identifier")}
          messageKey={messageKey}
          />
        <OrganizationPrunePeriodInput organization={organization}
          disabled={editingDisabled}
          onChange={changeOrganization("prunePeriod")}
          messageKey={messageKey}
        />
      </div>

      <div className="organization-card-meta">
        <div className="organization-card-ids">
          <div className="organization-card-aqumen-id">
            <span className="data-label">
              {intl.formatMessage({id: `organizations.organization.id.aqumen.label`})}
            </span>
            <span className="data-value">
              <DisplayId id={props.organization.id} disabled={confirmingArchive}/>
            </span>
          </div>
        </div>
        <div className="organization-card-actions">
          <ArchiveButton entity={props.organization}
            confirming={confirmingArchive}
            i18nPrefix="organizations.organization"
            onClick={() => {}}
            setConfirming={setConfirmingArchive}
            session={session}/>
        </div>
      </div>
    </div>
  );
}
