import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";
import {Card} from "@/library/layout/card.jsx";
import {CardData} from "@/library/layout/card_data.jsx";
import {CardTitle} from "@/library/layout/card_title.jsx";
import {CONFIRM_DESTRUCTIVE_TIMEOUT_MS, ONE_HOUR_MS} from "@/constants.js";
import {DataItem} from "@/library/layout/data_item.jsx";
import {selectDoubleFlat} from "@/data/select_double_flat.js";
import {selectFlat} from "@/data/select_flat.js";

function emptyReservation() {
  return {description: "", recipient: {id: null}};
}

const DESCRIPTION_MIN_WORDS = 0;

export function AdministrationReservationCardNew(props) {
  const intl = useIntl();

  const [blanks, setBlanks] = useState({
    activateAt: true, description: true, duration: true, recipient: true, system: true
  });
  const [invalids, setInvalids] = useState({
    activateAt: false, description: false, duration: false, recipient: false, system: false
  });
  const [confirming, setConfirming] = useState(false);
  const [reservation, setReservation] = useState(emptyReservation());
  const [working, setWorking] = useState(false);

  const session = useSelector(s => s.accessControlSession);
  const selectUnarchivedUsers = createSelector(
    selectFlat("user"), au => au.filter(u => !u.archivedAt)
  );
  const users = useSelector(selectUnarchivedUsers);

  const selectUnarchivedControlServers = createSelector(
    selectDoubleFlat("controlServer"), acs => acs.filter(cs => !cs.archivedAt)
  );
  const controlServers = useSelector(selectUnarchivedControlServers);

  const handleChangeActivateAt = (ev) => {
    if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, activateAt: true});
      setInvalids({...invalids, activateAt: false});
      setReservation(Object.assign({}, reservation, {activateAt: null}));
      return;
    }

    try {
      const activateAt = new Date(ev.target.value).getTime();
      setReservation(Object.assign({}, reservation, {activateAt}));
      setBlanks({...blanks, activateAt: false});
      setInvalids({...invalids, activateAt: false});
    } catch {
      setBlanks({...blanks, activateAt: false});
      setInvalids({...invalids, activateAt: true});
    }
  };

  const handleChangeDescription = (ev) => {
    setReservation(Object.assign({}, reservation, {description: ev.target.value}));
    if (ev.target.value.length > 0
        && (ev.target.value.match(/\S+/g)?.length ?? 0) < DESCRIPTION_MIN_WORDS) {
      setBlanks({...blanks, description: false});
      setInvalids({...invalids, description: true});
    } else if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, description: true});
      setInvalids({...invalids, description: false});
    } else {
      setBlanks({...blanks, description: false});
      setInvalids({...invalids, description: false});
    }
  };

  const handleChangeDuration= (ev) => {
    if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, duration: true});
      setInvalids({...invalids, duration: false});
      setReservation(Object.assign({}, reservation, {duration: null}));
      return;
    }

    try {
      const duration = parseInt(ev.target.value) * ONE_HOUR_MS;
      setReservation(Object.assign({}, reservation, {duration}));
      setBlanks({...blanks, duration: false});
      setInvalids({...invalids, duration: false});
    } catch {
      setBlanks({...blanks, duration: false});
      setInvalids({...invalids, duration: true});
    }
  };

  const handleChangeRecipient= (ev) => {
    if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, recipient: true});
      setInvalids({...invalids, recipient: false});
      setReservation(Object.assign({}, reservation, {recipient: {id: null}}));
      return;
    }

    setReservation(Object.assign({}, reservation, {recipient: {id: ev.target.value}}));
    setBlanks({...blanks, recipient: false});
    setInvalids({...invalids, recipient: false});
  };

  const handleChangeSystem= (ev) => {
    if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, system: true});
      setInvalids({...invalids, system: false});
      setReservation(Object.assign({}, reservation, {controlServer: {id: null}}));
      return;
    }

    setReservation(Object.assign({}, reservation, {controlServer: {id: ev.target.value}}));
    setBlanks({...blanks, system: false});
    setInvalids({...invalids, system: false});
  };

  const handleCreate = async () => {
    if (!confirming) {
      setConfirming(true);
      setTimeout(() => setConfirming(false), CONFIRM_DESTRUCTIVE_TIMEOUT_MS);
      return;
    }
    setConfirming(false);
    setWorking(true);
    try {
      const newReservation = Aqumen.Utility.create(
        "Reservation", session, {...reservation}
      );
      await Aqumen.Reservation.create(session, newReservation);
      setReservation(emptyReservation());
      setBlanks({description: true});
      setConfirming(false);
    } catch (e) {
      setConfirming(true);
      setTimeout(() => setConfirming(false), CONFIRM_DESTRUCTIVE_TIMEOUT_MS);
    } finally {
      setWorking(false);
    }
  };

  const disabled = working
    || Object.values(blanks).some(v => v)
    || Object.values(invalids).some(v => v);

  let className = Object.keys(invalids).reduce((r, k) => {
    return (invalids[k]) ? (r + " invalid-" + _.kebabCase(k)) : r;
  }, "card new focusable-item administration-reservation-card");
  if (working) {
    className += " working";
  }
  if (confirming) {
    className += " confirming";
  }
  return (
    <Card className={className} entity={reservation} i18nPrefix="administration.reservations.new">
      <CardTitle className="card-title">
        {intl.formatMessage({id: "administration.reservations.new.title"})}
      </CardTitle>
      <CardData>
        <DataItem labelId="system">
          <select defaultValue="" onChange={handleChangeSystem}>
            <option value="" disabled={true}>
              {intl.formatMessage({id: "administration.reservations.new.system.placeholder"})}
            </option>
            {controlServers.map(cs => (
              <option key={cs.id} value={cs.id}>{cs.identifier}</option>
            ))}
          </select>
        </DataItem>
        <DataItem labelId="activateAt">
          <input
            type="datetime-local"
            onChange={handleChangeActivateAt}/>
        </DataItem>
        <DataItem labelId="duration">
          <input
            type="number"
            step="1"
            min="1"
            max="1000"
            pattern="\d*"
            placeholder={intl.formatMessage({id: "administration.reservations.new.duration.placeholder"})}
            onChange={handleChangeDuration}/>
        </DataItem>
        <DataItem labelId="recipient">
          <select defaultValue="" onChange={handleChangeRecipient}>
            <option value="" disabled={true}>
              {intl.formatMessage({id: "administration.reservations.new.recipient.placeholder"})}
            </option>
            {users.map(u => (
              <option key={u.id} value={u.id}>{u.identifier}</option>
            ))}
          </select>
        </DataItem>
        <DataItem labelId="description">
          <textarea
            placeholder={intl.formatMessage({id: "administration.reservations.new.description.placeholder"})}
            type="text"
            disabled={working}
            onChange={handleChangeDescription}
            onKeyUp={blurOnEnter}
            value={reservation.description}/>
        </DataItem>
      </CardData>
      <div className="card-actions">
        <button className="create-button" disabled={disabled} onClick={handleCreate}>
          {!confirming && intl.formatMessage({id: "administration.reservations.new.create.button"})}
          {confirming && intl.formatMessage({id: "administration.reservations.new.create.confirm.button"})}
        </button>
      </div>
    </Card>
  );
}
