import React from "react";
import {useIntl} from "react-intl";

import {DisplayId} from "@/library/display/display_id.jsx";

export function AqumenIdItem({disabled, entity, i18nPrefix}) {
  const intl = useIntl();
  return (
    <div className="aqumen-id">
      <span className="data-label ">
        {intl.formatMessage({id: i18nPrefix + ".id.aqumen.label"})}
      </span>
      <span className="data-value">
        <DisplayId id={entity.id} disabled={disabled}/>
      </span>
    </div>
  );
}
