import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {reloadCurrentUser} from "@/data/user/reload_current_user.js";
import {SendButton} from "@/panes/users/send_button.jsx";

import {AccountPasskeyCard} from "./account_passkey_card.jsx";

export function AccountPasskeysPane(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);
  useEffect(() => {
    reloadCurrentUser(dispatch, session);
  }, []);

  return (
    <div className={"account-passkeys-pane pane " + (props.className ?? "")}>
      <div className="pane-top">
        <h1 className="pane-title">
          {intl.formatMessage({id: "account.passkeys.title"})}
        </h1>
      </div>
      <div className="account-send-registration">
        <SendButton user={session.user} i18nPrefix="account.passkeys"/>
        <p className="account-send-registration-help">
          {intl.formatMessage({id: "account.sendRegistration.help"})}
        </p>
      </div>
      <div className="account-passkeys-list focusable-collection">
        {session.user.passkeys.map(passkey =>
          <AccountPasskeyCard key={passkey.id} passkey={passkey}/>
        )}
      </div>
    </div>
  );
}
