// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.administration-reservations {
  button.reject {
    margin-right: 3rem;
  }
  button.action {
    margin-left: 1rem;
  }

  .card {
    &.pending {
      .card-title {
        color: var(--color-elevated-warn);
      }
    }
    &.approved .approval {
      .data-label,
      .data-value {
        color: var(--color-elevated-success);
      }
    }
    &.rejected .approval {
      .data-label,
      .data-value {
        color: var(--color-elevated-warn);
      }
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/style/panes/administration/administration_reservations.css"],"names":[],"mappings":";;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,iBAAiB;EACnB;;EAEA;IACE;MACE;QACE,iCAAiC;MACnC;IACF;IACA;MACE;;QAEE,oCAAoC;MACtC;IACF;IACA;MACE;;QAEE,iCAAiC;MACnC;IACF;EACF;AACF","sourcesContent":["\n\n.administration-reservations {\n  button.reject {\n    margin-right: 3rem;\n  }\n  button.action {\n    margin-left: 1rem;\n  }\n\n  .card {\n    &.pending {\n      .card-title {\n        color: var(--color-elevated-warn);\n      }\n    }\n    &.approved .approval {\n      .data-label,\n      .data-value {\n        color: var(--color-elevated-success);\n      }\n    }\n    &.rejected .approval {\n      .data-label,\n      .data-value {\n        color: var(--color-elevated-warn);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
