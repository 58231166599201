import Aqumen from "@aqumen/sdk";
import React, {useContext, useState} from "react";
import {create, parseCreationOptionsFromJSON} from "@github/webauthn-json/browser-ponyfill";
import {useIntl} from "react-intl"
import {useDispatch} from "react-redux";

import {ConnectionsContext} from "@/context/connections_context.js";
import LogoPasskey from "assets/icons/logo_passkey.svg";
import {processSession} from "./process_session.js";
import {accessControlRequest} from "./access_control_request.js";

export function RegisterPasskeyTile(props) {
  console.group("RegisterPasskeyTile");

  const dispatch = useDispatch();
  const intl = useIntl();
  const {setConnections} = useContext(ConnectionsContext);

  const registerButtonMessageId = (props.i18nPrefix)
    ? (props.i18nPrefix + ".button.register")
    : "registerPasskey.button.register";
  const ctaId = (props.i18nPrefix)
    ? (props.i18nPrefix + ".cta")
    : "registerPasskey.cta";
  const cta = intl.formatMessage({id: ctaId}, {product: c => c});
  const defaultStatus = {message: cta, classSuffix: "cta", done: false};

  const [registerStatus, setRegisterStatus] = useState(defaultStatus);

  let stepDelay = 0;
  const updateStep = (n) => {
    if (props.setCompletedSteps) {
      setTimeout(() => props.setCompletedSteps(n), stepDelay);
      stepDelay += 50;
    }
  };

  const registerAction = async () => {
    try {
      let message = intl.formatMessage({id: "registerPasskey.initializing"});
      setRegisterStatus({message, classSuffix: "normal", done: false})

      let initializePayload;
      if (props.replacePasskey) {
        initializePayload = {replacePasskey: {id: props.replacePasskey.id, name: props.replacePasskey.name}};
      } else {
        initializePayload = {registrationToken: props.registrationToken};
      }

      let initializeResponse;
      let initializeResponsePayload;
      try {
        initializeResponse = await accessControlRequest(
          "/registration/initialize", initializePayload
        );
        initializeResponsePayload = await initializeResponse.json();
      } catch (e) {
        const errorId = (new Aqumen.Error.AqumenError(e)).id;
        switch (e.name) {
          case "AbortError":
            setRegisterStatus({classSuffix: "error", message: intl.formatMessage(
              {id: "registerPasskey.initialize.exception.aborted"}, {errorId}
            )});
            break;
          case "NotAllowedError":
            setRegisterStatus(defaultStatus);
            props.setCompletedSteps(0);
            break;
          case "TypeError":
            setRegisterStatus({classSuffix: "error", message: intl.formatMessage(
              {id: "registerPasskey.initialize.exception.network"}, {errorId}
            )});
            break;
          default:
            setRegisterStatus({classSuffix: "error", message: intl.formatMessage(
              {id: "registerPasskey.initialize.exception.unknown"}, {errorId}
            )});
        }
        return;
      }

      if (initializeResponse.status >= 300) {
        const responseErrorMessage = initializeResponsePayload?.errors?.[0]?.message;
        const suffix = (responseErrorMessage === "invalid token")
          ? ".token.unrecognized"
          : responseErrorMessage.replace(/(invalid\s+|\s+)/g, ".");
        const messageId = "registerPasskey.error" + suffix;
        const defaultMessage = "default";
        const errorId = (new Aqumen.Error.AqumenError(initializeResponsePayload?.errors?.[0] || {})).id;
        message = intl.formatMessage({id: messageId, defaultMessage}, {errorId});
        if (message === defaultMessage) {
          const fallbackId = "registerPasskey.error.unknown.initializing";
          message = intl.formatMessage({id: fallbackId}, {errorId});
        }
        setRegisterStatus({message, classSuffix: "error"});
        return;
      }

      updateStep(1);
      message = intl.formatMessage({id: "registerPasskey.finalizing"});
      setRegisterStatus({message, classSuffix: "normal"});
      const createOptions = parseCreationOptionsFromJSON(initializeResponsePayload.data);

      const createResult = await create(createOptions);
      updateStep(2);

      let finalizeResponse;
      let finalizeResponsePayload;

      try {
        finalizeResponse = await accessControlRequest(
          "/registration/finalize", createResult
        );
        finalizeResponsePayload = await finalizeResponse.json();
      } catch (e) {
        const errorId = (new Aqumen.Error.AqumenError(e)).id;
        switch (e.name) {
          case "AbortError":
            setRegisterStatus({classSuffix: "error", message: intl.formatMessage(
              {id: "registerPasskey.finalize.exception.aborted"}, {errorId}
            )});
            break;
          case "NotAllowedError":
            setRegisterStatus(defaultStatus);
            props.setCompletedSteps(0);
            break;
          case "TypeError":
            setRegisterStatus({classSuffix: "error", message: intl.formatMessage(
              {id: "registerPasskey.finalize.exception.network"}, {errorId}
            )});
            break;
          default:
            setRegisterStatus({classSuffix: "error", message: intl.formatMessage(
              {id: "registerPasskey.finalize.exception.unknown"}, {errorId}
            )});
        }
        return;
      }

      updateStep(3);

      if (finalizeResponse.status >= 300) {
        message = intl.formatMessage(
          {id: "registerPasskey.error.unknown.finalizing"},
          {errorId: finalizeResponsePayload?.errors?.[0]?.id}
        );
        setRegisterStatus({message, classSuffix: "error"});
        return;
      }
      message = intl.formatMessage({id: "registerPasskey.passkeyAccepted"});
      setRegisterStatus({message, classSuffix: "success"});

      if (props.replacePasskey) {
        return;
      }

      const newConnections = await processSession(dispatch, finalizeResponsePayload);
      setConnections(newConnections);

      if (newConnections.length > 1) {
        message = intl.formatMessage({id: "registerPasskey.multipleEnvironments"});
        setSignInStatus({message, classSuffix: "success"});
        await new Promise(r => setTimeout(r, 750));
      }

      updateStep(4);
      setRegisterStatus(
        (props.resetOnDone) ? defaultStatus : {message, classSuffix: "normal", done: true}
      );

    } catch (e) {
      const errorId = (new Aqumen.Error.AqumenError(e)).id;
      switch (e.name) {
        case "NotAllowedError":
          setRegisterStatus(defaultStatus);
          props.setCompletedSteps(0);
          break;
        default:
          setRegisterStatus({classSuffix: "error", message: intl.formatMessage(
            {id: "registerPasskey.exception.unknown"}, {errorId}
          )});
      }
    }
  };

  const doneAction = () => {
    (props.doneCallback) ? doneCallback() : document.location.assign("/");
  }

  try {
    return (
      <div className={"tile register-passkey" + (props.className ?? "")}>
        {!registerStatus.done && (
          <button onClick={registerAction} className="button register">
            {intl.formatMessage({id: registerButtonMessageId})}
          </button>
        )}
        {registerStatus.done && (
          <button onClick={doneAction} className="button done">
            {intl.formatMessage({id: "registerPasskey.button.done"}, {product: c => c})}
          </button>
        )}
        <LogoPasskey aria-label={intl.formatMessage({id: "aria.label.logo.passkey"})}/>
        <p className={"help register-passkey cta status-area status-" + registerStatus.classSuffix}>
          {registerStatus.message}
        </p>
      </div>
    );
  } finally {
    console.groupEnd();
  }
}
