import _ from "lodash";
import {createSelector} from "@reduxjs/toolkit";

import {selectDoubleFlat} from "@/data/select_double_flat.js";
import {selectFlat} from "@/data/select_flat.js";
import {selectIncludeArchived} from "../preferences/select_include_archived.js";
import {selectWithSessions} from "@/data/select_with_sessions.js";

export const selectHydratedReservations = (pane) => createSelector(
  selectWithSessions("reservation"),
  selectDoubleFlat("controlServer"),
  selectFlat("user"),
  selectIncludeArchived(pane),
  (ar, acs, au, ia) => {
    const hydrated = ar.map(r => ({
      ...r,
      controlServer: acs.find(cs => cs.id === r.controlServer?.id),
      recipient: au.find(u => u.id === r.recipient?.id)
    }));
    const filtered = (ia)
      ? hydrated
      : hydrated.filter(r =>
        !r.archivedAt
        && !r.controlServer?.archivedAt
        && !r.recipient?.archivedAt
    );
    return _.orderBy(filtered,
      ["activateAt", "duration", "createdAt"],
      ["desc", "desc", "desc"]
    )
  }
);
