import React, {cloneElement, isValidElement} from "react";

export function CardData(props) {
  return (
    <div className={`card-data ${props.className || ""}`}>
      {
        React.Children.map(
          props.children, c => (isValidElement(c)) ? cloneElement(c, {...props, ...c.props}) : c
        )
      }
    </div>
  );
}
