import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";

import {CONFIRM_DESTRUCTIVE_TIMEOUT_MS} from "@/constants.js";
import {ERROR_VISUAL_TIMEOUT_MS} from "@/constants.js";
import {SERVICE_UPDATE_VISUAL_TIMEOUT_MS} from "@/constants.js";

export function ConfirmableButton({
    className = "", disabled, messageId, onClick,
    confirming, setConfirming,
    confirmTimeoutDuration = CONFIRM_DESTRUCTIVE_TIMEOUT_MS
  }) {

  const intl = useIntl();

  const confirmTimeout = useRef(null);
  const showErrorOnButtonTimeout = useRef(null);
  const showErrorTimeout = useRef(null);
  const workingTimeout = useRef(null);

  const [working, setWorking] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showErrorOnButton, setShowErrorOnButton] = useState(false);

  useEffect(() => () => {
    clearTimeout(confirmTimeout.current);
    clearTimeout(showErrorTimeout.current);
    clearTimeout(showErrorOnButtonTimeout.current);
  }, []);

  const handleClick = async (event) => {
    clearTimeout(confirmTimeout.current);

    if (confirming) {
      workingTimeout.current = setTimeout(() => {
        setWorking(false);
      }, SERVICE_UPDATE_VISUAL_TIMEOUT_MS);
      setWorking(true);

      try {
        setShowErrorOnButton(false);
        setError(null);
        await onClick({...event, confirmed: true});

      } catch (e) {
        console.error(e);
        setError(e);

        clearTimeout(showErrorTimeout.current);
        showErrorTimeout.current = setTimeout(() => {
          setShowError(false);
        }, ERROR_VISUAL_TIMEOUT_MS * 2);
        setShowError(true);

        clearTimeout(showErrorOnButtonTimeout.current);
        showErrorOnButtonTimeout.current = setTimeout(() => {
          setShowErrorOnButton(false);
        }, ERROR_VISUAL_TIMEOUT_MS);
        setShowErrorOnButton(true);

      } finally {
        setWorking(false);
      }

      setConfirming(false);

    } else {
      confirmTimeout.current = setTimeout(() => {
        setConfirming(false);
      }, confirmTimeoutDuration);

      await onClick({...event, confirmed: false});

      setConfirming(true);
    }
  };

  let currentClassName = className;
  let currentMessageId = messageId;

  if (showError) {
    currentClassName += " error focused";
  }

  if (showErrorOnButton) {
    currentMessageId += ".error.label";
    disabled = true;

  } else if (working) {
    currentClassName += " working focused";
    currentMessageId += ".working";

  } else if (confirming) {
    currentClassName += " confirming focused";
    currentMessageId += ".confirming";
  }

  return (
    <React.Fragment>
      <button className={currentClassName} disabled={disabled} onClick={handleClick}>
        {intl.formatMessage({id: currentMessageId})}
      </button>

      {showError && (
        <div className="error-popover-for-button">
          {intl.formatMessage({id: `${messageId}.error`}, {errorId: error.id})}
        </div>
      )}
    </React.Fragment>
  );
}
