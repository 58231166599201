// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pane.permissions-pane .permissions-table {
  margin: 1rem;
  text-align: left;
}
.pane.permissions-pane th, .pane.permissions-pane td {
  padding: 0.3rem 1rem;
  white-space: nowrap;
}
.pane.permissions-pane tbody:not(:has(.focused)) tr {
  color: var(--color-elevated-text);
}
.pane.permissions-pane tbody:has(.focused) tr:not(.focused) {
  opacity: 0.5;
}
.pane.permissions-pane tr.focused td {
  background-color: var(--color-selected-canvas);
  color: var(--color-elevated-text);
}
.pane.permissions-pane tbody tr:nth-child(odd) td {
  background-color: var(--color-selected-canvas);
}`, "",{"version":3,"sources":["webpack://./src/style/panes/access_control/access_control_permissions.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,gBAAA;AAAJ;AAEE;EACE,oBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,iCAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,8CAAA;EACA,iCAAA;AAHJ;AAME;EACE,8CAAA;AAJJ","sourcesContent":[".pane.permissions-pane {\n  .permissions-table {\n    margin: 1rem;\n    text-align: left;\n  }\n  th, td {\n    padding: 0.3rem 1rem;\n    white-space: nowrap;\n  }\n\n  tbody:not(:has(.focused)) tr {\n    color: var(--color-elevated-text);\n  }\n\n  tbody:has(.focused) tr:not(.focused) {\n    opacity: 0.5;\n  }\n\n  tr.focused td {\n    background-color: var(--color-selected-canvas);\n    color: var(--color-elevated-text);\n  }\n\n  tbody tr:nth-child(odd) td {\n    background-color: var(--color-selected-canvas);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
