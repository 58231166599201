import React, {cloneElement, isValidElement, useContext} from "react";

import {CardContext} from "./card_context.js";

export function Card({children, className, i18nPrefix}) {
  const cardContext = useContext(CardContext);

  let currentClassName = `${className} card`;
  if (cardContext.archived) {
    currentClassName += " archived";
  }
  if (cardContext.confirming) {
    currentClassName += " confirming";
  }
  console.debug("CARDCONTEXT", cardContext)

  return (
    <div className={currentClassName}>
      {
        React.Children.map(
          children, c => (isValidElement(c)) ? cloneElement(c, {i18nPrefix, ...c.props}) : c
        )
      }
    </div>
  );
}
